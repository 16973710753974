import React from "react"
class Input extends React.Component {
  handleChange(e) {
    const { name } = this.props
    const { value } = e.target
    this.props.onValueChange({ name, value })
  }
  render() {
    return (
      <div className={`form-group ${this.props.required && "required"}`}>
        <div className="form-floating">
          {this.props.type === "textarea" && (
            <textarea
              type={this.props.type}
              className="form-control"
              aria-label={this.props.title}
              placeholder={`${this.props.title} ${
                (this.props.required && "*") || ""
              }`}
              id={`id${this.props.name}`}
              name={this.props.name}
              rows="4"
              required={this.props.required}
              autoComplete={this?.props?.autocomplete}
              value={this.props.value}
              onChange={(e) => this.handleChange(e)}
            />
          )}
          {this.props.type !== "textarea" && (
            <input
              type={this.props.type}
              className="form-control"
              aria-label={this.props.title}
              placeholder={`${this.props.title} ${
                (this.props.required && "*") || ""
              }`}
              name={this.props.name}
              id={`id${this.props.name}`}
              rows="4"
              required={this.props.required}
              autoComplete={this?.props?.autocomplete}
              value={this.props.value}
              onChange={(e) => this.handleChange(e)}
            />
          )}
          <div className="invalid-feedback">{this.props.error}</div>
          <label htmlFor={`id${this.props.name}`}>
            {this.props.title} {(this.props.required && "*") || ""}
          </label>
        </div>
      </div>
    )
  }
}
export default Input
