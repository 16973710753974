import React, { useState } from "react"
import Layout from "../Layout"
import Form from "./Form"
import { motion } from "framer-motion"
import DynamicLink from "../DynamicLink"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

function Item({ awnser, onClick }) {
  return (
    <div className="awnsers" onClick={onClick}>
      <li className="item" style={{ borderColor: "#fff" }}></li>
      <div className="awnser">{awnser}</div>
    </div>
  )
}

const questions = [
  {
    title: "Konst & kultur",
    question:
      "I Örebro anordnas Skandinaviens största biennal för samtidskonst, OpenART. Under OpenArt invaderas stadens gator, torg och vattendrag, kulturscener, köpcenter och fasader av konstnärer från hela världen. Vad innebär det att en utställning är biennal? ",
    awnsers: [
      { awnser: "Den återkommer vartannat år", points: 1 },
      { awnser: "Den är utomhus", points: 0 },
      { awnser: "Den har konstnärer från hela världen", points: 0 },
    ],
    x: "0% 50%",
    xMobile: "8% 50%",
  },
  {
    title: "Mat & shopping",
    question:
      "Örebro har ett rikt restaurangliv med koncept som bidrar till att du vill äta frukost, lunch och middag på lokal. Lägg undan alla tankar på dieter och ge dig ut på en matresa genom världens alla hörn. Från vilket land hämtar Boulebar sina influenser?",
    awnsers: [
      { awnser: "Spanien", points: 0 },
      { awnser: "Italien", points: 0 },
      { awnser: "Frankrike", points: 1 },
    ],
    x: "25% 50%",
    xMobile: "23% 50%",
  },
  {
    title: "Hem & bostad",
    question:
      "Vi människor tycker om att ha nära till saker som jobb, nöje och natur. Och i Örebro bor folk märkligt nära det mesta. Nära till jobbet, till stan, Oslo, Stockholm och vildmarken. Inom en radie på 30 mil från Örebro bor en stor del av Sveriges befolkning, hur stor del?",
    awnsers: [
      { awnser: "50 procent", points: 0 },
      { awnser: "60 procent", points: 0 },
      { awnser: "70 procent", points: 1 },
    ],
    x: "50% 50%",
    xMobile: "50% 50%",
  },
  {
    title: "Arbete & studier",
    question:
      "AI Impact Lab är ett samarbete mellan Örebro universitet och Region Örebro län. Samarbetet har accelererat AI-utvecklingen i regionen och gjort Örebro till ett AI-nav i Sverige, vilket förstås är både smart och superrimligt. Vad står AI för?",
    awnsers: [
      { awnser: "Artificiell intelligens", points: 1 },
      { awnser: "Abstrakt illustration", points: 0 },
      { awnser: "Allvarlig infektion", points: 0 },
    ],
    x: "75% 50%",
    xMobile: "73% 50%",
  },
  {
    title: "Sport & fritid",
    question:
      "Folk brukar tycka att sport är viktigt. Att man ska röra på sig och inte ligga hemma i soffan och äta chips. Sen finns det dom som tycker att det är viktigt att sitta hemma, titta på en elitmatch och äta chips. I Örebro funkar båda. Här finns elitlag inom nästan alla sporter. Vad kallar Roller Derby-lag sina nybörjarprogram?",
    awnsers: [
      { awnser: "Ettagluttare", points: 0 },
      { awnser: "Fresh meat", points: 1 },
      { awnser: "Frosh", points: 0 },
    ],
    x: "100% 50%",
    xMobile: "100% 50%",
  },
]
const endText = [
  "Nu glömmer vi det här!",
  "Glöm aldrig: det viktigaste är inte att vinna utan att delta.",
  "Idag var inte din dag, bättre lycka nästa gång!",
  "HEJA! Du är på helt rätt väg.",
  "Imponerande, du är smartare än de flesta!",
  "Wow! Vi väntar på dig, välkommen till Örebro!",
]

const imageTransition = {
  ease: [0.7, 0, 0.3, 1],
  duration: 2,
}

const Component = (props) => {
  const { data } = props
  const [swiper, setSwiper] = useState(null)
  const slideTo = (index) => swiper?.slideTo(index)
  const [slidePos, setSlidePos] = useState("0% 50%")
  const [slidePosMobile, setSlidePosMobile] = useState("8% 50%")
  const [respond, setRespond] = useState([])
  const [slide, setSlide] = useState(0)

  const pageData = data?.wpPage?.template?.quizPageData
  const pageMeta = data?.wpPage?.pageMeta

  console.log(props)

  const image = pageMeta?.image?.localFile?.childImageSharp?.fluid

  const result = respond.reduce((a, b) => ({ points: a.points + b.points }), {
    points: 0,
  })

  const nextSlide = (questionId, awnserId, obj) => {
    slideTo(slide + 1)
    setSlide(slide + 1)
    if (questionId >= 0 && respond.length <= questionId) {
      setRespond([...respond, obj])
    }
  }
  return (
    <Layout
      location={props.location}
      title={pageMeta?.title}
      description={pageMeta?.desc}
      className="front"
      menu={false}
      frontpage={true}
      image={image}
    >
      <div className="relative quiz">
        <div className="image-wrap">
          <motion.img
            className="d-none d-md-block"
            src={pageData.backgroundImage.localFile.childImageSharp.fluid.src}
            alt="Smart Move"
            animate={{ objectPosition: slidePos }}
            transition={imageTransition}
          />
          <motion.img
            className="d-md-none mobile"
            src={
              pageData.backgroundImageMobile.localFile.childImageSharp.fluid.src
            }
            alt="Smart Move"
            animate={{ objectPosition: slidePosMobile }}
            transition={imageTransition}
          />
        </div>
        <div
          className="swiper-wrap"
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Swiper
            onSwiper={(swiper) => {
              setSwiper(swiper)
            }}
            onSlideChange={(e) => {
              if (questions[e.activeIndex - 1]) {
                setSlidePos(questions[e.activeIndex - 1].x)
                setSlidePosMobile(questions[e.activeIndex - 1].xMobile)
              }
            }}
            allowTouchMove={false}
            speed={1500}
            keyboard={{ enabled: false }}
          >
            <SwiperSlide key="0">
              <div className="full-wrap w-100 h-100">
                <div className="row m-0 h-100 align-items-center justify-content-center">
                  <div className="col-12">
                    <DynamicLink
                      inline
                      href="/"
                      className="d-block mx-auto mb-3 mb-md-0 text-center"
                    >
                      <svg
                        version="1.0"
                        style={{ height: "2rem" }}
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 1086 140.6"
                        xmlSpace="preserve"
                        alt="Smart Move"
                        name="Smart Move"
                      >
                        <path
                          fill="#fff"
                          d="M44.7,46.5c12.1,0,19.3,4.1,23.6,13.9l20.3-9.8c-8-16.4-23.2-25-43.9-25c-24.6,0-39.8,12.1-39.8,32.8
      c0,43.6,60,27.5,60,49.4c0,8.2-7.2,11.7-19.1,11.7c-12.5,0-21.3-3.5-25.4-14.6L0,112.7c8.2,20.3,24.8,27.9,45.1,27.9
      c26.2,0,43.6-12.7,43.6-34c0-42-60-27.9-60-50C28.7,50.2,33.8,46.5,44.7,46.5z M432.7,25.4C402.8,25.4,386,42,386,73.2v43.6h-19.5
      v21.9h76.2v-21.9h-32.4V73.2c0-15.8,8.6-25.2,21.7-25.2c9.4,0,15.8,4.7,18.9,13.1l20.5-10.7C465.5,35.2,453.8,25.4,432.7,25.4z
      M246.5,112.5V67.2c0-27.7-13.1-41.8-34-41.8c-13.1,0-23.4,5.5-29.5,16.4c-5.7-10.9-15.8-16.4-28.9-16.4c-10.9,0-20.1,3.9-26.6,11.5
      v-9.6h-22.3v111.3h24.2V68.8c0-13.9,7.2-20.7,17.2-20.7c9.4,0,17.4,6.4,17.4,18.7v71.9h24.2V68.8c0-13.9,7-20.7,16.8-20.7
      c9.4,0,17.2,6.4,17.2,18.7v45.7c0,22.3,8.8,28.1,24,28.1c4.3,0,8.4-0.6,11.9-2v-20.9c-2.1,0.6-3.9,0.8-5.9,0.8
      C248.9,118.6,246.5,117,246.5,112.5z M313.1,25.4c-21.7,0-35.5,7.8-45.5,27.9l20.7,10.9c4.5-10.9,12.5-16.2,24.4-16.2
      c12.9,0,19.9,6.1,19.9,21.1v5.1h-26.2c-28.5,0-41.4,13.5-41.4,33c0,18.9,13.7,33.4,38.1,33.4c13.9,0,24.6-4.5,31.4-13.7v11.7h22.3
      V71.1C356.9,38.5,341.2,25.4,313.1,25.4z M307.1,118.8c-12.9,0-17.8-5.1-17.8-12.1c0-9,6.1-12.9,17.2-12.9h26.2
      C332.5,112.3,321.3,118.8,307.1,118.8z M954.6,27.3c3.7,11.5,5.1,25.8-4.7,48.8l-17.4,41.4h-1.2l-28.1-90.2h-25.4l38.3,111.3h31.3
      l25.8-60.7c10-23.2,10-38.5,6.8-50.6H954.6z M1086,81.6c0-38.7-16.6-56.3-47.3-56.3c-30.5,0-50.4,17.4-50.4,57.6
      c0,39.1,18.9,57.6,50.8,57.6c19.7,0,35.5-7.8,45.5-26l-19.7-9.8c-5.9,10.2-14.3,14.3-25.2,14.3c-17.2,0-25.8-8.4-27.5-28.3h73.8
      V81.6z M1012.4,71.3c2.3-16.6,10.2-24.4,25.4-24.4c15.6,0,23,8,25,24.4H1012.4z M537.4,118.8c-11.9,0-18.6-7.8-18.6-20.1V49.2H561
      V27.3h-42.2V0h-24.2v27.3H475v21.9h19.5v49.4c0,25.4,13.5,42,42.2,42c16.6,0,27.7-6.6,36.7-17.8l-17.6-13.3
      C551,115.8,545.6,118.8,537.4,118.8z M827.6,25.4c-32.8,0-50.8,20.3-50.8,57.6c0,37.1,18,57.6,50.8,57.6c32.8,0,50.8-20.5,50.8-57.6
      C878.4,45.7,860.4,25.4,827.6,25.4z M827.6,118c-18,0-26.4-11.3-26.4-35c0-23.6,8.4-35,26.4-35c18,0,26.4,11.3,26.4,35
      C854,106.6,845.6,118,827.6,118z M761.2,112.5V67.2c0-27.7-13.1-41.8-34-41.8c-13.1,0-23.4,5.5-29.5,16.4
      c-5.7-10.9-15.8-16.4-28.9-16.4c-10.9,0-20.1,3.9-26.6,11.5v-9.6H620v111.3h24.2V68.8c0-13.9,7.2-20.7,17.2-20.7
      c9.4,0,17.4,6.4,17.4,18.7v71.9H703V68.8c0-13.9,7-20.7,16.8-20.7c9.4,0,17.2,6.4,17.2,18.7v45.7c0,22.3,8.8,28.1,24,28.1
      c4.3,0,8.4-0.6,11.9-2v-20.9c-2.1,0.6-3.9,0.8-5.9,0.8C763.6,118.6,761.2,117,761.2,112.5z"
                        />
                      </svg>
                    </DynamicLink>
                    <h1>
                      Är du smart nog <br />
                      för ett smart move?
                    </h1>
                    <div className="row m-0 justify-content-center w-100">
                      <div className="col-12 col-md-8">
                        <p>
                          Det är alltid smart att göra ett smart move. Oavsett
                          om det handlar om att ge dig ut på en löprunda, våga
                          säga vad du känner till någon som du tycker om eller
                          flytta till en plats som du är nyfiken på. På{" "}
                          <DynamicLink to="/">thesmartmove.se</DynamicLink> kan
                          du läsa om Örebroregionen, om spännande platser och om
                          intressanta människor som har gjort ett smart move.
                        </p>

                        <p>
                          Var med och tävla i vårt quiz och ha chans att vinna
                          en weekend för två med övernattning i Örebro.
                        </p>

                        <motion.button
                          className="mt-5 mb-3"
                          onClick={() => {
                            nextSlide()
                          }}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          disabled={slide !== 0 || false}
                        >
                          Starta quiz
                        </motion.button>
                        <p className="small-link text-center">
                          <DynamicLink to="/quiz/tavlingsregler/">
                            Information om tävlingen, villkor med mera.
                          </DynamicLink>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {questions.map((obj, questionId) => (
              <SwiperSlide key={questionId + 1}>
                <div className="question w-100 h-100">
                  <div className="row m-0 h-100 align-items-center justify-content-center">
                    <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                      <div className="question-wrap">
                        <h3>
                          Fråga{" "}
                          {Math.floor(questionId + 1)
                            .toString()
                            .padStart(2, "0")}
                        </h3>
                        <h2>{obj.title}</h2>
                        <p>{obj.question}</p>
                        <ul className="questions">
                          {obj.awnsers.map((obj, awnserId) => (
                            <Item
                              key={questionId + "" + awnserId}
                              awnser={obj.awnser}
                              onClick={() => {
                                nextSlide(questionId, awnserId, obj)
                              }}
                            />
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <SwiperSlide key="6">
              <div className="full-wrap w-100 h-100">
                <div className="row m-0 h-100 align-items-center justify-content-center">
                  <div className="col-12">
                    <h3 className="text-center">
                      {result.points} av {questions.length} rätt
                    </h3>
                    <h1 className="mb-0 pb-0 pt-0">{endText[result.points]}</h1>

                    <div className="row m-0 justify-content-center w-100 pt-3 pt-md-5">
                      <div className="col-12 col-md-8">
                        <p>
                          Fyll i dina uppgifter och ha chans att vinna en
                          weekend för två i Örebro!
                        </p>
                        <Form
                          points={result.points}
                          json={respond}
                          target="https://admin.thesmartmove.se/wp-json/quiz/v1/post/"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </Layout>
  )
}

Component.defaultProps = {
  imageX: { start: 0, end: 0 },
  imageY: { start: 0, end: 0 },
  zoom: { start: 0, end: 0 },
  imageOffset: [null],
  alt: "",
}

export default Component
