import React from "react"
import { graphql } from "gatsby"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import Quiz from "../components/Quiz/Quiz"

const Page = (props) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdNuEscAAAAAPHk7jSz8Y4NawjT5Amh0XQge5Jx"
      scriptProps={{
        async: true,
        defer: true,
      }}
    >
      <Quiz {...props} />
    </GoogleReCaptchaProvider>
  )
}
export default Page

export const pageQuery = graphql`
  query ($uri: String!) {
    wpPage(uri: { eq: $uri }) {
      pageMeta {
        desc
        title
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, toFormat: JPG, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
      template {
        ... on WpTemplate_QuizPage {
          templateName
          quizPageData {
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 9600
                    quality: 85
                    toFormat: JPG
                    srcSetBreakpoints: [10, 9600]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
              altText
            }
            backgroundImageMobile {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 4800
                    quality: 85
                    toFormat: JPG
                    srcSetBreakpoints: [10, 7200]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     top: file(relativePath: { eq: "content/assets/smartmove-career.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 2400, toFormat: JPG, quality: 85) {
//           ...GatsbyImageSharpFluid_withWebp
//           presentationWidth
//           presentationHeight
//         }
//       }
//     }
//     bg: file(relativePath: { eq: "brold.png" }) {
//       childImageSharp {
//         fluid(
//           maxWidth: 9600
//           quality: 85
//           toFormat: JPG
//           srcSetBreakpoints: [10, 9600]
//         ) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//     bgMobile: file(relativePath: { eq: "brold.png" }) {
//       childImageSharp {
//         fluid(
//           maxWidth: 4800
//           quality: 85
//           toFormat: JPG
//           srcSetBreakpoints: [10, 7200]
//         ) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `;
