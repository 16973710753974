import React, { useState, useRef } from "react"
import Input from "./Input"
import { motion } from "framer-motion"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const Component = ({ target, json, points }) => {
  const row = 1
  const form = useRef(null)
  const [formData, setFormData] = useState({})
  const [formClass, setFormClass] = useState("needs-validation")
  const { executeRecaptcha } = useGoogleReCaptcha()

  const reduceInputValues = (inputElements) => {
    const arrElements = Array.prototype.slice.call(inputElements)
    const formValues = arrElements
      .filter((elem) => elem.name.length > 0)
      .map((x) => {
        const { typeMismatch } = x.validity
        const { name, type, value } = x
        return {
          name,
          type,
          typeMismatch, //we use typeMismatch when format is incorrect(e.g. incorrect email)
          value,
          valid: x.checkValidity(),
        }
      })
      .reduce((acc, currVal) => {
        //then we finally use reduce, ready to put it in our state
        const { value, valid, typeMismatch } = currVal
        acc[currVal.name] = {
          value,
          valid,
          typeMismatch,
        }
        return acc
      }, {})
    return formValues
  }

  const checkAllFieldsValid = (inputValues) => {
    return !Object.keys(inputValues)
      .map((x) => inputValues[x])
      .some((field) => !field.valid)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!executeRecaptcha) {
      return
    }
    const tokenCaptcha = await executeRecaptcha("quiz")

    const submitButton = form.current.querySelector("button")
    const inputValues = reduceInputValues(form.current.elements)
    const allFieldsValid = checkAllFieldsValid(inputValues)
    if (allFieldsValid) {
      submitButton.setAttribute("disabled", "")
      submitButton.innerHTML = "Skickar"

      fetch(target, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          debug: false,
          points: points,
          json: JSON.stringify(json),
          token: tokenCaptcha,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.data.status === 200) {
            submitButton.innerHTML = "Skickat!"
            alert(response.message)
          } else {
            console.log("Request failed", response)
            submitButton.removeAttribute("disabled")
            submitButton.innerHTML = "Försök igen!"
          }
        })
        .catch(function (error) {
          console.log("Request failed", error)
          submitButton.removeAttribute("disabled")
          submitButton.innerHTML = "Försök igen!"
        })
    } else {
      submitButton.innerHTML = "Fyll i allt först!"
    }
  }

  const handleSubmitClick = () => {
    setFormClass("needs-validation was-validated")
  }

  const handleInputChange = (e) => {
    const value = e.value
    const name = e.name
    setFormData({ ...formData, [name]: value })
  }
  return (
    <form
      className={formClass}
      action={target}
      method="post"
      onSubmit={handleSubmit}
      ref={form}
      noValidate
    >
      <div className="row">
        <div className="col-12 mb-3">
          <Input
            type="text"
            name="name"
            title="Namn"
            error="Fyll i ditt namn."
            autocomplete="name"
            required={true}
            value={formData?.name ?? ""}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="text"
            name="cellphone"
            autocomplete="tel"
            title="Mobilnummer"
            error="Fyll i ditt mobilnummer."
            required={true}
            value={formData?.cellphone ?? ""}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="col-12 mb-3">
          <Input
            type="email"
            name="email"
            title="E-postadress"
            error="Fyll i din e-post."
            autocomplete="email"
            required={true}
            value={formData?.email ?? ""}
            onValueChange={(e) => handleInputChange(e)}
          />
        </div>
      </div>

      <div className="form-check mb-4">
        <input
          type="checkbox"
          className="form-check-input"
          aria-labelledby="inputAgreement"
          name="agreement"
          id={`inputAgreementCheckbox${row}`}
          onChange={() => {
            setFormData({
              ...formData,
              agreement: !(formData?.agreement ?? false),
            })
          }}
          checked={formData?.agreement ?? false}
          required
        />
        <label
          className="form-check-label"
          id={`inputAgreement${row}`}
          htmlFor={`inputAgreementCheckbox${row}`}
        >
          Jag godkänner att mina uppgifter hanteras av Örebrokompaniet.
        </label>

        <div className="invalid-feedback">
          Du måste godkänna villkoren innan du skickar in.
        </div>
      </div>

      <motion.button
        type="submit"
        onClick={handleSubmitClick}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Skicka
      </motion.button>
      <div className="row small-text mt-4">
        <div className="col-12">
          <p>
            reCAPTCHA from Google{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </a>{" "}
            and{" "}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              user terms
            </a>
            .
          </p>
        </div>
      </div>
    </form>
  )
}
export default Component
